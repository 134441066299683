import './order.scss';

import React from 'react';
import JotformEmbed from 'react-jotform-embed';
import Layout from 'components/Layout/Layout';

const mainClassName = 'order';

const Terms = () => {
  return (
    <Layout>
      <div className={mainClassName}>
        <JotformEmbed src="https://submit.jotform.com/222438242610245"/>
      </div>
    </Layout>
  );
};

export default Terms;
